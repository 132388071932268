<template>
    <div class="wrapper">
        <CRow>
            <CCol lg="12">
                <CCard>
                    <CCardHeader><font-awesome-icon icon="fa-barcode"/>  Categories
                        <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>                          
                    </CCardHeader>            
                    <CCardBody>
                        <CTabs  :active-tab="my_tab" @update:activeTab="changeTab">
                            <br>  
                            <CTab>
                                <template slot="title">
                                    Active Category/ies <CBadge color="success">( {{ [...new Set(dtc_lists_active.map((item) => item.category))].length }} )</CBadge>
                                </template>
                                <CRow>
                                    <CCol lg="12">
                                        <CDataTable
                                            :items="dtc_lists_active"
                                            :fields="dtc_lists_fields"
                                            :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                                            :items-per-page="10"
                                            border
                                            items-per-page-select
                                            sorter
                                            pagination
                                            :loading="this.table_loading"
                                        >
                                        <template slot="no-items-view" v-if="this.table_loading">
                                            <center>
                                                <CSpinner
                                                    style="width:4rem;height:4rem;"
                                                    color="info"
                                                />
                                            </center>
                                        </template>
                                        <template #status="{item}">
                                                <td>
                                                    <CBadge :color="getColorStatus(item.status)">
                                                    {{ item.status }}
                                                    </CBadge>
                                                </td>
                                            </template>
                                        <template #category="{item}">
                                                <td>  
                                                    {{item.category ? item.category : ''}}   
                                                </td>
                                        </template>
                                        <template #attachment_set="{item}">
                                            <td>
                                                <small>
                                                    <i v-if="parseInt(item.document_template_category_set_count) > 0">
                                                        With Sets of Attachment Checklist
                                                    </i>
                                                    <i v-else>
                                                        No Sets of Attachment Checklist
                                                    </i>
                                                </small>
                                            </td>
                                        </template>
                                        <template #color="{item}">
                                                <td>
                                                    <div :style="{backgroundColor: item.color, width: '100%', height: '26px'}"></div>
                                                </td>
                                        </template>
                                        <template #created_at="{item}">
                                                <td>
                                                    {{  `${changeDateFormatV2(item.created_at)}` }}
                                                </td>
                                            </template>   
                                        <template #Action="{item}">
                                                <td>
                                                    <router-link :to="{path: `/${$store.getters['getOrganizationName']}/drs/document-template-category/${item.id}`}">
                                                        <CButton 
                                                            color="info" 
                                                            shape="pill" 
                                                            size="sm"
                                                        >
                                                            <font-awesome-icon icon="cog"/> Manage
                                                        </CButton>
                                                    </router-link>
                                                </td>
                                        </template> 
                                        </CDataTable>
                                    </CCol> 
                                </CRow>
                            </CTab>  
                            <CTab title="">
                                <template slot="title">
                                    Inactive Category/ies <CBadge color="danger">( {{ [...new Set(dtc_lists_inactive.map((item) => item.category))].length }} )</CBadge>
                                </template>
                                <CRow>
                                    <CCol lg="12">
                                        <CDataTable
                                            :items="dtc_lists_inactive"
                                            :fields="dtc_lists_fields"
                                            :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                                            :items-per-page="10"
                                            border
                                            items-per-page-select
                                            sorter
                                            pagination
                                            :loading="this.table_loading"
                                        >
                                        <template #status="{item}">
                                                <td>
                                                    <CBadge :color="getColorStatus(item.status)">
                                                    {{ item.status }}
                                                    </CBadge>
                                                </td>
                                        </template>
                                        <template #category="{item}">
                                                <td>  
                                                    {{item.category ? item.category : ''}}   
                                                </td>
                                        </template>
                                        <template #color="{item}">
                                                <td>
                                                    <div :style="{backgroundColor: item.color, width: '100%', height: '26px'}"></div>
                                                </td>
                                        </template>
                                        <template #attachment_set="{item}">
                                            <td>
                                                <small>
                                                    <i v-if="parseInt(item.document_template_category_set_count) > 0">
                                                        With Sets of Attachment Checklist
                                                    </i>
                                                    <i v-else>
                                                        No Sets of Attachment Checklist
                                                    </i>
                                                </small>
                                            </td>
                                        </template>
                                        <template #updated_at="{item}">
                                                <td>
                                                  <small>{{  `${changeDateFormat(item.updated_at)}` }}</small>  
                                                </td>
                                            </template>
                                        <template #Action="{item}">
                                            <td >
                                                <template v-if="$store.getters['can']('restore-document-template-category')">
                                                    <!-- <CButton 
                                                        size="sm"
                                                        color="success"  
                                                        @click="restore(item)"
                                                    >
                                                        <font-awesome-icon icon="trash-restore"/> Restore
                                                    </CButton> -->


                                                    <CButton 
                                                        size="sm"
                                                        shape="pill"
                                                        color="success" 
                                                        :disabled="restoring ? (item.id == restoring_id ? true : false) : false"
                                                        @click="restore(item)"
                                                    >

                                                        <font-awesome-icon :icon="restoring ? (item.id == restoring_id ? 'circle-notch' : 'trash-restore') : 'trash-restore'" 
                                                                    :pulse="restoring ? (item.id == restoring_id ? true : false) : false"/> Restore
                                                    </CButton>




                                                </template>
                                                <template v-else>
                                                   <i><b>No permission to restore.</b></i>
                                                </template>
                                            </td>
                                        </template>    
                                        </CDataTable>
                                    </CCol> 
                                </CRow>
                            </CTab>
                        </CTabs>
                    </CCardBody>        
                </CCard>
            </CCol>
        </CRow>
    </div>
 </template>
 <script>
 export default {
     name: 'DocumentTemplateCategoryLists',
     data() {
         return {
            
            restoring: false,
            restoring_id: null,
             table_loading: true,
             my_tab: 0,
             dtc_lists: [],
             document_template_category: { 
                id: null, 
                category: null,
                color: null,
                status: null,
            },
            document_template_category_backup: {
                id: null,
                category: null,
                color: null,
                status: null,
            },
         }
     },
    created() {
        this.getDocumentTemplateCategory();
        this.$emit('activeTab', 0);
    },
    computed: {
        dtc_lists_fields: function () {
            let custom_fields= [
                { key: 'category' },
                { key: 'color' },
                { key: 'attachment_set', label: 'Variant' },
            ]

            if(this.my_tab) {
                custom_fields.push({ key: 'updated_at', label: 'Inactivity Date' })
            } else {
                custom_fields.push({ key: 'created_at', label: 'Date Added' })
            }

            if(this.$store.getters.can('view-document-template-category')) {
                custom_fields.push({ key: 'Action', sorter: false})
            }
            return custom_fields;
        },
        dtc_lists_active: function() {
            return this.dtc_lists.filter((item) => item.deleted_at == null);
        },
        dtc_lists_inactive: function() {
            return this.dtc_lists.filter((item) => item.deleted_at != null);
        }

    },
    methods: {
        changeTab: function (item) {
            this.my_tab = item
        },      
        getDocumentTemplateCategory: function () {
            this.$Progress.start()
            axios.get('/drs/document-template-category/list', {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.dtc_lists = response.data.data
                    this.table_loading = false;
                    this.$Progress.finish()    
                }
            })
        },
        restore: function (item) {
            this.restoring = true
            this.restoring_id = item.id;
            this.$Progress.start()
            axios.post('/drs/document-template-category/restore/'+ item.id, {validateStatus: () => true})
            .then(response => {
                if ( response.status == 200 ) {
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        icon: 'success',
                        title: `${item.category} has been restored.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })
                    this.dtc_lists.filter(obj => obj.id === item.id)[0].deleted_at = null;
                    this.$Progress.finish() 
                }
            })
        }
    },
}
</script>
